#mobileMu{
	display: none;
}
.scrollTop{
	position: fixed;
	right: 20px;
	bottom: 10px;
	z-index: 10000000;
	max-width: 50px;
	display: none;
	border-radius: 100%;
	opacity: 0.5;
	img{
		width: 100%;
	}
	&.active{
		display: block;
	}
}
header{
	height: 100px;
	padding-top: 25px;
	padding: 0 span(0.3) 0 span(0.3);
	position: absolute;
	width: 100%;
	z-index: 2;
	&.fix-nav {
		top: 0;
		left: 0;
		width: 100%;
		z-index: 10000;
		overflow: hidden;
		transition: all 0.5s;   
		&.fix-nav-shrink{
			position: fixed;
			height: 70px;
			box-shadow: 0 2px 5px rgba(0,0,0,.1);
			border-bottom: 5px solid #4c4c4c;
			background: #000;
			ul.menu{
				top: -5px;
				a{
					color: #ffffff;
					&:after{
						background: #fff;
					}
					&:hover{
						color: #000;
						&:after{
							top: 0;
						}
					}
				}
			}
			a.logo{
				img{
					filter: brightness(100);
				}
			}
		}
	}
	
	a.logo{
		margin-top: 20px;
		display: inline-block;
		img{
			max-width: 100%;
		}
	}
	ul.menu{
		position: absolute;
		right: span(0.5);
		top: 0px;
		lost-utility: clearfix;
		transition: all 0.5s;   
		li{
			float: left;
			white-space: nowrap;
			position: relative;
			&.active{
				a{
					color: #ffffff;
					h1{
						color: #000000;
					}
					&:after{
						top: 0;
					}
				}
			}
		}
		h1{
			position: relative;
			z-index: 2;
		}
		a{
			font-weight: bold;
			display: block;
			width: 100%;
			height: 80px;
			text-align: center;
			line-height: 80px;
			padding: 0 25px;
			color: #000;
			transition: 300ms all ease-in-out;
			&:after{
				content: '';
				position: absolute;
				background: #000;
				width: 100%;
				height: 100%;
				left: 0;
				top: -100px;
				transition: 300ms all ease-in-out;
			}
			&:hover{
				color: #ffffff;
				&:after{
					top: 0;
				}
			}
		}
	}
}
ul.react-tabs__tab-list{
	lost-utility: clearfix;
	float: left;
	margin-left: span(2.8 of 14);
	border: 0;
	li{
		float: left;
		margin-right: 10px;
		display: block;
		line-height: 55px;
		text-align: center;
		width: 150px;
		height: 55px;
		border: 2px solid #000;
		border-radius: 0;
		color: #000000;
		transition: 150ms all ease-in-out;
		position: static;
		padding: 0;
		&:hover{
			background: #000000;
			color: #ffffff;
		}
		&:last-child{
			margin-right: 0;
		}
		&.react-tabs__tab--selected{
			background: #000;
			color: #fff;
		}
	}
}
.tab-content{
	&>.tab-pane {
		display:none
	}
	&>.active {
		display:block
	}
}
.cTitle{
	margin: 25px 0 5px 0;
}
.mcTitle{
	margin: 25px 0 5px 0;
	display: none;
}
.classTable{
	width: 100%;
	font-size: 12px;
	font-family: $font-ming;
	&.three{
		h2{
			&.adv{
				color: #c76917;
			}
			&.started{
				color: #8C9F09;
			}
			&.normal{
				color: #4D9BB3;
			}
		}
	}
	a{
		background: #000;
		color: #fff;
		padding: 3px 2px;
		display: inline-block;
		border-radius: 3px;
		margin: 3px auto;
	}
	.heart{
		display: block;
		margin: 0 auto 5px auto;
	}
	th,td{
		padding: 10px 0;
		text-align: center;
	}
	h2{
		margin-bottom: 8px;
		font-family: 'Roboto', sans-serif;
		white-space: nowrap;
		line-height: 18px;
		letter-spacing: -0.5px;
		&.adv{
			color: #c76917;
		}
		&.started{
			color: #4D9BB3;
		}
		&.normal{
			color: #8C9F09;
		}
	}
	h4{
		color: #524d4d;
		line-height: 15px;
	}
	tbody{
		td{
			&:nth-child(even){ 
				background: #f7f7f7;
			}
		}
	}
	thead{
		tr{
			color: #ffffff;
		}
		th,td{
			background: #000;
		}
		th{
			overflow: hidden;
			&:first-child{
				border-radius: 5px 0 0 0;
			}
			&:last-child{
				border-radius: 0 5px 0 0;
			}
		}
		div{
			float: left;
			width: 50%;
			text-align: center;
		}
	}
}
.mTable{
	width: 100%;
	font-size: 12px;
	font-family: $font-ming;
	&.three{
		h2{
			&.adv{
				color: #c76917;
			}
			&.started{
				color: #8C9F09;
			}
			&.normal{
				color: #4D9BB3;
			}
		}
	}
	a{
		background: #000;
		color: #fff;
		padding: 3px 2px;
		display: inline-block;
		border-radius: 3px;
		margin: 3px auto;
	}
	.heart{
		display: block;
		margin: 0 auto 5px auto;
	}
	th,td{
		padding: 10px 0;
		text-align: center;
	}
	h2{
		margin-bottom: 2px;
		font-family: 'Roboto', sans-serif;
		white-space: nowrap;
		line-height: 18px;
		letter-spacing: -0.5px;
		&.adv{
			color: #c76917;
		}
		&.started{
			color: #4D9BB3;
		}
		&.normal{
			color: #8C9F09;
		}
	}
	
	h4{
		color: #524d4d;
		line-height: 15px;
	}
	thead{
		tr{
			color: #ffffff;
		}
		th,td{
			background: #000;
			padding: 15px 0;
			text-align: center;
		}
		th,td{
			overflow: hidden;
			&:first-child{
				border-radius: 5px 0 0 0;
			}
			&:last-child{
				border-radius: 0 5px 0 0;
			}
		}
		div{
			float: left;
			width: 50%;
			text-align: center;
		}
	}
	tbody{
		th{
			color: #000;
			vertical-align: middle;
			padding: 5px;
		}
		
		tr{
			[rowspan*='2']{
				background: #f3f3f3;
				// border-bottom: 1px solid #dcdcdc;
				border: 1px solid #dcdcdc;
			}
			&:nth-child(even){
				background: #f3f3f3;
				border-bottom: 1px solid #dcdcdc;
				border-top: 1px solid #dcdcdc;
				th{
					border-bottom: 1px solid #dcdcdc;
				}
			}
			td{
				vertical-align: middle;
				&:last-child{
					border-right: 1px solid #dcdcdc;;
				}
			}
			&.time{
				th{
					padding: 15px 0;
				}
			}
		}
	}
}

