$font-yahei:Arial, Helvetica,\5FAE\8EDF\6B63\9ED1\9AD4, sans-serif;
$font-ming: Arial, Helvetica, \7EC6\660E\4F53, sans-serif;
$img-path:"/assets/images"; //

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.eot');
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
