@media screen and (max-width: $pad-900) {
    ul.react-tabs__tab-list{
        margin-left: span(3.5);
    }
    dl#ind{
        dd{
            &#location{
                ul.react-tabs__tab-list{
                    width: span(10 of 14);
                    a{
                        width: 100%;
                        padding: 0 span(0.5);
                        white-space: nowrap;
                    }
                    li{
                        lost-column: 1/4 4 8px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 700px) {
    ul.react-tabs__tab-list{
        width: 100%;
        margin-left: 0;
        li{
            lost-column: 1/3 3 5PX;
            a{
                width: 100%;
            }
        }
    }
    dl#ind{
        dd{
            &#location{
                ul.react-tabs__tab-list{
                    width: 100%;
                    li{
                        lost-column: 1/4 4 8px;
                    }
                }
            }
        }
    }
}