@media screen and (max-width: $mobile-640) {
    dl#ind{
        dd{
            &#price{
                ul.price{
                    >li{
                        padding: span(0.5);
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 500px) {
    dl#ind{
        dd{
            padding: 80px 0;
            &#price{
                ul.price{
                    >li{
                        lost-column: 1/1 1 0;
                        padding: span(0.5) span(1);
                    }
                    h3{
                        text-align: left;
                    }
                    .class{
                        position: absolute;
                        width: 80px;
                        height: 80px;
                        line-height: 80px;
                        font-size: 48px;
                        right: 15px;
                        top: 15px;
                    }
                }
            }
            &#location{
                .map{
                    height: 300px;
                }
            }
        }
    }
}
@media screen and (max-width: $mobile-468) {
    dl#ind{
        dd{
            &#about{
                p{
                    margin-top: 10px;
                    line-height: 20px;
                    font-size: 13px;
                }
                .left{
                    height: 100px;
                }
            }
            &#price{
                ul.price{
                    h3{
                        padding-top: 40px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 350px) {
    dl#ind{
        dd{
            &#class{
                ul.level{
                    text-align: center;
                }   
            }
        }
    }
}
